import {styled} from "@mui/system";
import {useSurveyEngine} from "src/hooks/use-survey-engine";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {
  AppBar,
  Button,
  Collapse,
  GlobalStyles,
  LinearProgress,
  Paper,
  Slide,
  Typography,
} from "@mui/material";
import {QuestionFactory} from "src/pages/survey/question-factory";
import {createTheme, Stack, ThemeProvider} from "@mui/system";
import {LoadingButton} from "@mui/lab";
import {useTranslation} from "react-i18next";
import lodash from "lodash";
import CheckQuestionAnswer from "src/pages/survey/check-answers";
import Alert from "@mui/material/Alert";
import {
  displayLogic,
  checkSkipLogic,
} from "src/pages/survey/check-question/display-logic";
import EndPage from "src/pages/survey/end-page";
import {useTheme} from "@emotion/react";
import {animationTypes} from "src/schemas/survey/survey";
import {createLightOptions} from "src/theme/light";
import {GifSplashScreen} from "src/components/gif-splash-screen";
import {useSettings} from "src/hooks/use-settings";
import HelpBubble from "../main/contact/help-buble";

const ScrollableContent = styled(Box)({
  flex: 1,
  overflowY: "auto",
});

export const Survey = () => {
  const settings = useSettings();
  const surveyEngine = useSurveyEngine();
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [skipPageQuestionIndex, setSkipPageQuestionIndex] = useState(null);
  const [pageQuestions, setPageQuestions] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const {t} = useTranslation();
  const [pageErrors, setPageErrors] = useState({});
  const [surveyParameters, setSurveyParameters] = useState([]);
  const [scrolledToBottom, setScrolledToBottom] = useState(true);

  // Disable scroll for body and disable keyboard focus scale change bug
  useEffect(() => {
    const metaViewport = document.createElement("meta");
    metaViewport.name = "viewport";
    metaViewport.content =
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
    document.head.appendChild(metaViewport);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
      document.head.removeChild(metaViewport);
    };
  }, []);

  const handleScroll = event => {
    const target = event.target;
    const scrollTop = target.scrollTop;
    const clientHeight = target.clientHeight;
    const scrollHeight = target.scrollHeight;

    // Check if there is enough content to be scrollable
    if (scrollHeight >= clientHeight) {
      // Only set `scrolledToBottom` to true if within 50px of the bottom and it's not already true
      if (scrollTop + clientHeight >= scrollHeight - 50 && !scrolledToBottom) {
        setScrolledToBottom(true);
      }
    }
  };

  useEffect(() => {
    const container = document.querySelector(".scrollable-content");
    if (container) {
      const scrollTop = container.scrollTop;
      const clientHeight = container.clientHeight;
      const scrollHeight = container.scrollHeight;

      if (scrollHeight >= clientHeight) {
        if (scrollTop + clientHeight >= scrollHeight - 50 && !scrolledToBottom) {
          setScrolledToBottom(true);
        }
      }
    }
  }, [pageQuestions, isLoading]);

  const generalTheme = useTheme();

  const [showQuestion, setShowQuestion] = useState(true);
  const [logoSettings] = useState({
    logo: null,
    placement: "Center",
    verticalPlacement: "Top",
    maxHeight: 50,
    maxWidth: 100,
  });
  const [generalSettings] = useState({
    progressBar: "WithText",
    progressBarPlacement: "Center",
    progressBarVerticalPlacement: "Top",
    nextButton: " → ",
    previousButton: " ← ",
  });
  const [styleSettings] = useState({
    fontFamily: generalTheme.typography.fontFamily,
    questionFontSize: 18,
    answerFontSize: 16,
    primaryColor: generalTheme.palette.primary.main,
    secondaryColor: generalTheme.palette.secondary.main,
  });
  const [backgroundSettings] = useState({
    backgroundColor: generalTheme.palette.background.default,
  });
  const [motionSettings] = useState({
    pageTransition: "none", //"slide",
    translationDirection: "right",
  });

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const uniqueParameters = {};
    for (const [key, value] of queryParameters.entries()) {
      uniqueParameters[key] = value;
    }
    const nameValueParameters = [];
    Object.keys(uniqueParameters).forEach(key =>
      nameValueParameters.push({name: key, value: uniqueParameters[key]}),
    );
    setSurveyParameters(nameValueParameters);
  }, []);

  useEffect(() => {
    if (surveyEngine.survey.getCurrentBlockQuestionCount() === 0) {
      surveyEngine.handleCompleteSubmission();
      setIsEnd(true);
    } else {
      let currPageQuestions = surveyEngine.survey.getCurrentBlockQuestionsByPageIndex(
        pageIndex,
        surveyEngine.submission.answersMap,
      );
      if (currPageQuestions.length === 0) {
        handleNextButton();
      } else {
        if (skipPageQuestionIndex) {
          currPageQuestions = currPageQuestions.slice(skipPageQuestionIndex);
          setSkipPageQuestionIndex(null);
        }
        setPageQuestions(currPageQuestions);
      }
    }
  }, [surveyEngine.survey.currentBlock, pageIndex]);

  const newTheme = createTheme(
    {
      ...generalTheme,
      palette: {
        ...generalTheme.palette,
        primary: {
          main: styleSettings?.primaryColor, // Primary color
        },
        secondary: {
          main: styleSettings?.secondaryColor, // Secondary color
        },
        background: {
          default: backgroundSettings?.backgroundColor, // Custom default background color
        },
      },
      typography: {
        ...generalTheme.typography,
        fontFamily: styleSettings?.fontFamily,
        fontSize: styleSettings?.answerFontSize,
        h6: {
          fontSize: styleSettings?.questionFontSize,
        },
      },
    },
    createLightOptions(),
  );

  const Animation =
    motionSettings?.pageTransition !== "none" ||
    motionSettings?.pageTransition !== "slide" ? (
      animationTypes[motionSettings?.pageTransition]
    ) : (
      <></>
    );

  const checkQuestionAnswers = pageQuestions => {
    const errors = {};
    pageQuestions.forEach(question => {
      const checkQuestion = CheckQuestionAnswer(
        question,
        surveyEngine.submission.answersMap[question.id],
      );
      if (checkQuestion !== true) {
        errors[question.id] = checkQuestion;
      }
    });
    setPageErrors(errors);
    return lodash.isEmpty(errors);
  };

  const handleNextButton = _ => {
    setShowQuestion(false);
    setIsLoading(true);
    if (checkQuestionAnswers(pageQuestions)) {
      if (!scrolledToBottom) {
        settings.showAlert("warning", t("scrollDownWarningMessage"));
        setIsLoading(false);
        setShowQuestion(true);
      } else {
        const pageQuestionIds = pageQuestions.map(qu => qu.id);
        surveyEngine.handlePageAnswers(pageQuestionIds, surveyParameters).then(_ => {
          let skipPageIndex = null;
          for (const pageQuestion of pageQuestions) {
            let skipLogic = checkSkipLogic(
              pageQuestion,
              surveyEngine.survey.allQuestionsMap,
              surveyEngine.submission.answersMap,
            );
            if (skipLogic) {
              if (skipLogic.type === "end_of_survey") {
                surveyEngine.handleCompleteSubmission();
                setIsEnd(true);
                setIsLoading(false);
                setShowQuestion(true);
              } else if (skipLogic.type === "end_of_block") {
                surveyEngine
                  .handleGetNextBlock(surveyEngine.survey.nextFlowElementId)
                  .then(_ => {
                    setPageIndex(0);
                    setIsLoading(false);
                    setShowQuestion(true);
                  });
              } else if (skipLogic.type === "question") {
                for (
                  let i = pageIndex + 1;
                  i < surveyEngine.survey.getCurrentBlockPageCount();
                  i++
                ) {
                  let tempPageQuestions =
                    surveyEngine.survey.getCurrentBlockQuestionsByPageIndex(
                      i,
                      surveyEngine.submission.answersMap,
                    );
                  if (tempPageQuestions.length === 0) {
                    handleNextButton();
                  } else {
                    const questionIndex = tempPageQuestions.findIndex(
                      qu => qu.id === skipLogic.destination_question_id,
                    );
                    if (questionIndex > -1) {
                      skipPageIndex = i;
                      setSkipPageQuestionIndex(questionIndex);
                      break;
                    }
                  }
                }
              }
            }
          }
          if (skipPageIndex) {
            setPageIndex(skipPageIndex);
            setIsLoading(false);
            setShowQuestion(true);
          } else if (pageIndex === surveyEngine.survey.getCurrentBlockPageCount() - 1) {
            if (surveyEngine.survey.nextFlowElementId === null) {
              surveyEngine.handleCompleteSubmission();
              setIsEnd(true);
              setIsLoading(false);
              setShowQuestion(true);
            } else {
              surveyEngine
                .handleGetNextBlock(surveyEngine.survey.nextFlowElementId)
                .then(_ => {
                  setPageIndex(0);
                  setIsLoading(false);
                  setShowQuestion(true);
                });
            }
          } else {
            setPageIndex(pageIndex + 1);
            setIsLoading(false);
            setShowQuestion(true);
          }
        });
      }
    } else {
      settings.showAlert("warning", t("nextPageWarningMessage"));
      setIsLoading(false);
      setShowQuestion(true);
    }
  };

  const percentage = surveyEngine.submission.isCompleted
    ? 100
    : !surveyEngine.remainingQuestionCount
      ? 0
      : (
          (surveyEngine.survey.totalQuestionsCount -
            surveyEngine.remainingQuestionCount) /
          surveyEngine.survey.totalQuestionsCount
        ).toFixed(2);

  if (pageQuestions === null) {
    return <>Loading</>;
  }

  if (isEnd || surveyEngine.submission.isCompleted) {
    return <EndPage />;
  }

  const TOP_BAR_HEIGHT =
    (logoSettings?.verticalPlacement === "Top"
      ? Number(logoSettings.maxHeight) + 16
      : 0) +
    (generalSettings?.progressBar !== "None" &&
    generalSettings?.progressBarVerticalPlacement === "Top"
      ? 42
      : 0);
  const BOTTOM_BAR_MARGIN = 60;
  const BOTTOM_BAR_HEIGHT =
    (logoSettings?.verticalPlacement === "Bottom"
      ? Number(logoSettings.maxHeight) + 16
      : 0) +
    (generalSettings?.progressBar !== "None" &&
    generalSettings?.progressBarVerticalPlacement === "Bottom"
      ? 42
      : 0);
  const MAX_WIDTH = 720;

  return (
    <ThemeProvider theme={newTheme}>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: backgroundSettings?.backgroundColor,
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100svh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: `100%`,
            height: "100%",
          }}
        >
          {(logoSettings?.verticalPlacement === "Top" ||
            (generalSettings?.progressBar !== "None" &&
              generalSettings?.progressBarVerticalPlacement === "Top")) && (
            <AppBar
              position={"fixed"}
              sx={{
                height: TOP_BAR_HEIGHT,
                width: "100%",
                left: 0,
                right: 0,
                margin: "0 auto",
                backgroundColor: "#f5f5f5",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  height: TOP_BAR_HEIGHT,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: 1,
                  width: "100%",
                  maxWidth: `${MAX_WIDTH}px`,
                }}
              >
                {logoSettings?.verticalPlacement === "Top" && (
                  <Stack
                    width="100%"
                    height="100%"
                    flexDirection="column"
                    alignItems={
                      logoSettings?.placement === "Left"
                        ? "flex-start" // Sol hizalama
                        : logoSettings?.placement === "Right"
                          ? "flex-end" // Sağ hizalama
                          : "center" // Orta hizalama
                    }
                    justifyContent="center" // Dikeyde ortalama
                  >
                    <Box width={200} display="flex" justifyContent="center" m={1}>
                      {logoSettings?.logo ? (
                        <img
                          src={logoSettings?.logo}
                          alt="Selected"
                          width={100}
                          style={{
                            maxHeight: Number(logoSettings?.maxHeight) || "auto",
                            maxWidth: Number(logoSettings?.maxWidth) || "auto",
                            objectFit: "contain", // Ensure image fits within container
                          }}
                        />
                      ) : (
                        <img
                          className="logo"
                          src="/logo.png"
                          alt="Galata Anket logo"
                          width="71"
                          height={Number(logoSettings?.maxHeight) || "50"}
                          style={{
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </Box>
                  </Stack>
                )}

                {generalSettings?.progressBar !== "None" &&
                  generalSettings?.progressBarVerticalPlacement === "Top" && (
                    <Stack
                      width="100%" // Konteyner genişliğini tam genişlik yapın
                      alignItems={
                        generalSettings?.progressBarPlacement === "Left"
                          ? "flex-start" // Sola hizala
                          : generalSettings?.progressBarPlacement === "Right"
                            ? "flex-end" // Sağa hizala
                            : "center" // Ortala
                      }
                      justifyContent="center" // Dikey ortala
                      direction="column" // Dikey yerleşim
                    >
                      <Box width={200} textAlign={"center"} m={1}>
                        <Box width="100%">
                          <LinearProgress
                            variant="determinate"
                            value={percentage * 100}
                          />
                        </Box>
                        {generalSettings?.progressBar === "WithText" && (
                          <Typography
                            variant="body2"
                            align="center"
                            sx={{color: "black"}}
                          >
                            {parseFloat(percentage * 100).toFixed(0)}%
                          </Typography>
                        )}
                      </Box>
                    </Stack>
                  )}
              </Box>
            </AppBar>
          )}

          {/* Center content */}
          <ScrollableContent
            className="scrollable-content"
            onScroll={showQuestion && !isLoading ? handleScroll : undefined}
            sx={{
              marginTop: `${TOP_BAR_HEIGHT}px`,
              marginBottom: `${BOTTOM_BAR_HEIGHT + BOTTOM_BAR_MARGIN}px`, // Adjust margin for the two bottom AppBars
              padding: 2,
            }}
          >
            {motionSettings?.pageTransition === "none" ? (
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
              >
                {pageQuestions.map((question, index) => (
                  <Paper
                    key={index}
                    elevation={3}
                    sx={{
                      width: "100%",
                      maxWidth: `${MAX_WIDTH}px`,
                      mb: 2,
                      p: 4,
                      backgroundColor: "rgba(255, 255, 255, 0.9)", // Hafif şeffaf bir arka plan
                      borderRadius: 3, // Yuvarlatılmış köşeler
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Daha belirgin bir gölge
                    }}
                  >
                    <Box key={question.id} sx={{my: 4}}>
                      <Typography
                        textAlign={"justify"}
                        dangerouslySetInnerHTML={{__html: question.text}}
                      />
                      <Collapse
                        in={pageErrors[question.id] && pageErrors[question.id] !== true}
                      >
                        <Alert severity="error">
                          <strong>{t(pageErrors[question.id])}</strong>
                        </Alert>
                      </Collapse>
                      <QuestionFactory question={question} />
                    </Box>
                  </Paper>
                ))}
              </Box>
            ) : motionSettings?.pageTransition === "slide" ? (
              <Slide
                direction={motionSettings?.translationDirection}
                in={showQuestion}
                mountOnEnter
                unmountOnExit
                sx={{alignItems: "center"}}
              >
                <Stack
                  sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  {pageQuestions.map((question, index) => (
                    <Paper
                      key={index}
                      elevation={3}
                      sx={{
                        width: "100%",
                        maxWidth: `${MAX_WIDTH}px`,
                        mb: 2,
                        p: 4,
                        backgroundColor: "rgba(255, 255, 255, 0.9)", // Hafif şeffaf bir arka plan
                        borderRadius: 3, // Yuvarlatılmış köşeler
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Daha belirgin bir gölge
                      }}
                    >
                      <Box key={question.id} sx={{my: 4}}>
                        <Typography
                          textAlign={"justify"}
                          dangerouslySetInnerHTML={{__html: question.text}}
                        />
                        <Collapse
                          in={pageErrors[question.id] && pageErrors[question.id] !== true}
                        >
                          <Alert severity="error">
                            <strong>{t(pageErrors[question.id])}</strong>
                          </Alert>
                        </Collapse>
                        <QuestionFactory question={question} />
                      </Box>
                    </Paper>
                  ))}
                </Stack>
              </Slide>
            ) : (
              <Animation in={showQuestion} sx={{alignItems: "center"}}>
                <Stack
                  sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  {pageQuestions.map((question, index) => (
                    <Paper
                      key={index}
                      elevation={3}
                      sx={{
                        width: "100%",
                        maxWidth: `${MAX_WIDTH}px`,
                        mb: 2,
                        p: 4,
                        backgroundColor: "rgba(255, 255, 255, 0.9)", // Hafif şeffaf bir arka plan
                        borderRadius: 3, // Yuvarlatılmış köşeler
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Daha belirgin bir gölge
                      }}
                    >
                      <Box key={question.id} sx={{my: 4}}>
                        <Typography
                          textAlign={"justify"}
                          dangerouslySetInnerHTML={{__html: question.text}}
                        />
                        <Collapse
                          in={pageErrors[question.id] && pageErrors[question.id] !== true}
                        >
                          <Alert severity="error">
                            <strong>{t(pageErrors[question.id])}</strong>
                          </Alert>
                        </Collapse>
                        <QuestionFactory question={question} />
                      </Box>
                    </Paper>
                  ))}
                </Stack>
              </Animation>
            )}
          </ScrollableContent>

          {/* Bottom AppBar */}

          <AppBar
            position={"fixed"}
            color="primary"
            sx={{
              top: "auto",
              bottom: BOTTOM_BAR_HEIGHT,
              height: BOTTOM_BAR_MARGIN,
              width: "100%",
              left: 0,
              right: 0,
              margin: "0 auto",
              backgroundColor: "#f5f5f5",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: BOTTOM_BAR_MARGIN,
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                justifySelf: "center",
                alignItems: "center",
                padding: "0 20px",
                width: "100%",
                maxWidth: `${MAX_WIDTH}px`,
              }}
            >
              {surveyEngine.survey.show_previous_button ? (
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  variant="contained"
                  // onClick={() => handleButton(-1)}
                  // disabled={currentQuestion === 0}
                >
                  {generalSettings?.previousButton
                    ? generalSettings?.previousButton
                    : "Previous"}
                </Button>
              ) : (
                <div />
              )}

              <LoadingButton
                disabled={isLoading}
                loading={isLoading}
                variant="contained"
                onClick={handleNextButton}
              >
                {generalSettings?.nextButton ? generalSettings?.nextButton : t("next")}
              </LoadingButton>
            </Box>
          </AppBar>

          {(logoSettings?.verticalPlacement === "Bottom" ||
            (generalSettings?.progressBar !== "None" &&
              generalSettings?.progressBarVerticalPlacement === "Bottom")) && (
            <AppBar
              position="fixed"
              color="primary"
              sx={{
                top: "auto",
                bottom: 0,
                height: BOTTOM_BAR_HEIGHT,
                width: "100%",
                left: 0,
                right: 0,
                margin: "0 auto",
                backgroundColor: "#f5f5f5",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  height: BOTTOM_BAR_HEIGHT,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: 1,
                  width: "100%",
                  maxWidth: `${MAX_WIDTH}px`,
                }}
              >
                {logoSettings?.verticalPlacement === "Bottom" && (
                  <Stack
                    width="100%"
                    height="100%"
                    flexDirection="column"
                    alignItems={
                      logoSettings?.placement === "Left"
                        ? "flex-start" // Sol hizalama
                        : logoSettings?.placement === "Right"
                          ? "flex-end" // Sağ hizalama
                          : "center" // Orta hizalama
                    }
                    justifyContent="center" // Dikeyde ortalama
                  >
                    <Box width={200} display="flex" justifyContent="center">
                      {logoSettings?.logo ? (
                        <img
                          src={logoSettings?.logo}
                          alt="Selected"
                          width={100}
                          style={{
                            maxHeight: Number(logoSettings?.maxHeight) || "auto",
                            maxWidth: Number(logoSettings?.maxWidth) || "auto",
                            objectFit: "contain", // Ensure image fits within container
                          }}
                        />
                      ) : (
                        <img
                          className="logo"
                          src="/logo.png"
                          alt="Galata Anket logo"
                          width="71"
                          height={Number(logoSettings?.maxHeight) || "50"}
                          style={{
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </Box>
                  </Stack>
                )}
                {generalSettings?.progressBar !== "None" &&
                  generalSettings?.progressBarVerticalPlacement === "Bottom" && (
                    <Stack
                      width="100%" // Konteyner genişliğini tam genişlik yapın
                      alignItems={
                        generalSettings?.progressBarPlacement === "Left"
                          ? "flex-start" // Sola hizala
                          : generalSettings?.progressBarPlacement === "Right"
                            ? "flex-end" // Sağa hizala
                            : "center" // Ortala
                      }
                      justifyContent="center" // Dikey ortala
                      direction="column" // Dikey yerleşim
                    >
                      <Box width={200} textAlign={"center"} m={2}>
                        <Box width="100%">
                          <LinearProgress
                            variant="determinate"
                            value={percentage * 100}
                          />
                        </Box>
                        {generalSettings?.progressBar === "WithText" && (
                          <Typography
                            variant="body2"
                            align="center"
                            sx={{color: "black"}}
                          >
                            {parseFloat(percentage * 100).toFixed(0)}%
                          </Typography>
                        )}
                      </Box>
                    </Stack>
                  )}
              </Box>
            </AppBar>
          )}
        </Box>
      </Box>
      <HelpBubble bottom={BOTTOM_BAR_MARGIN + BOTTOM_BAR_HEIGHT} />
    </ThemeProvider>
  );
};
